var site = site || {};
var generic = generic || {};
var TFBadges = TFBadges || {};

generic.endeca.result.product = jQuery.extend(true, {}, generic.endeca.result.product, {
  displayResult: function(args) {
    this.setupReviewData();
    this.setupBrandData();

    // Cast IMAGES to arrays
    this.makeArray('IMAGE_M');
    this.makeArray('MEDIUM_IMAGE');
    this.makeArray('IMAGE_L');
    this.makeArray('LARGE_IMAGE');
    this.makeArray('LARGE_ALT_IMAGES');

    // defaultSku
    this.resultData.defaultSku = _.find(this.resultData.skus, { 'DEFAULT_SKU': 1 }) || this.resultData.skus[0];
    // set defaultSku images to arrays
    this.resultData.defaultSku.IMAGE_M = $.makeArray(this.resultData.defaultSku.IMAGE_M);
    this.resultData.defaultSku.MEDIUM_IMAGE = $.makeArray(this.resultData.defaultSku.MEDIUM_IMAGE);
    this.resultData.defaultSku.IMAGE_L = $.makeArray(this.resultData.defaultSku.IMAGE_L);
    this.resultData.defaultSku.LARGE_IMAGE = $.makeArray(this.resultData.defaultSku.LARGE_IMAGE);
    this.resultData.defaultSku.LARGE_ALT_IMAGES = $.makeArray(this.resultData.defaultSku.LARGE_ALT_IMAGES);

    // Convert MISC_FLAG_TEXT to original form of comma list to match GS.
    if (Array.isArray(this.resultData.MISC_FLAG_TEXT)) {
      this.resultData.MISC_FLAG_TEXT = this.resultData.MISC_FLAG_TEXT.join(',');
    }
    // Updated Badges for OOS, TOOS, Coming soon on Search result pages similar in MPP 
    var consensusBadgeObj = Drupal.settings.consensus_badge_mapping.INVENTORY_STATUS_FLAG.translate || {};
    if (_.isObject(consensusBadgeObj) && !_.isEmpty(consensusBadgeObj)) {
      var outofstockString = '';
      var oosStatus = 2;
      var comingSoonStatus = 3;
      var soStatus = 7;
      var oosOccurrenceList = this.checkInvStatus(oosStatus, this.resultData.skus);
      var soldoutOccurrence = this.checkInvStatus(soStatus, this.resultData.skus);
      var comingsoonOccurrence = this.checkInvStatus(comingSoonStatus, this.resultData.skus);
      if (this.resultData.skus.length === oosOccurrenceList.length) {
        outofstockString = consensusBadgeObj[oosStatus];
      } else if (this.resultData.skus.length === soldoutOccurrence.length) {
        outofstockString = consensusBadgeObj[soStatus];
      } else if (this.resultData.skus.length === comingsoonOccurrence.length) {
        outofstockString = consensusBadgeObj[comingSoonStatus];
      }
      if (this.resultData.MISC_FLAG_TEXT !== undefined) {
        this.resultData.MISC_FLAG_TEXT = [this.resultData.MISC_FLAG_TEXT, outofstockString].join(',');
      } else {
        this.resultData.MISC_FLAG_TEXT = outofstockString;
      }
    }
    // Setup product badges
    // TODO: also need to support inventory badges? But we don't do L2...
    if (Drupal.settings.product_badge_mpp_ts && this.resultData.MISC_FLAG_TEXT) {
      var flag_hash = TFBadges.generateBaseFlagHash(this.resultData);
      var badge_ts_pack = Drupal.settings.product_badge_mpp_ts;
      var badges = TFBadges.generateBadgeItems(flag_hash, badge_ts_pack);
      this.resultData.PRODUCT_BADGES = badges;
    }

    this._super(args);
  },

  makeArray: function(fieldName) {
    if (this.resultData[fieldName] && typeof this.resultData[fieldName] !== 'object') {
      this.resultData[fieldName] = [this.resultData[fieldName]];
    }
  },
  checkInvStatus: function(invStatus, productObj) {
    if (typeof productObj !== undefined && invStatus > 0) {
      var occuranceList = jQuery.grep(productObj, function(sku) {
        return sku.INVENTORY_STATUS === invStatus;
      });
      return occuranceList;
    }
  }
});

site.endeca.result.product = generic.endeca.result.product;
