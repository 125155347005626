var site = site || {};
var generic = generic || {};

generic.endeca.results.bestsellers = jQuery.extend(true, {}, generic.endeca.results.bestsellers, {
  displayResultNodes: function() {
    this._super();
    jQuery(document).trigger('endeca.search.results.loaded', [{
      results: {
        products: {
          resultData: this.resultData
        }
      }
    }]);
  }
});

site.endeca.results.bestsellers = generic.endeca.results.bestsellers;
