/*
  Sitewide Endeca configuration.
  Anything set here will be used as the default for all settings used by the endeca instances
  on the page. These can be overwritten in the instance configuration files for each
  endeca instance.

*/
var site = site || {};
var generic = generic || {};

jQuery(function() {
  site.endeca.configuration = {
    query: {
      MDEXHost: 'localhost',
      MDEXPort: Drupal?.settings?.endeca?.ports?.mdex,
      logHost: 'localhost',
      logPort: Drupal?.settings?.endeca?.ports?.log,

      defaultDimensionIds: [
        8157,
        8095,
        8186,
        8063,
        8174,
        8051,
        8089,
        8195,
        8173,
        8050
      ],

      configuredRangeFilters: {
        skuShoppable: 's_shoppable|GT+0',
        skuPromotional: 's_promotional|GT+0',
        skuSearchable: 's_searchable|GT+0',
        productTrFlag: 'p_TR_FLAG|LT+1',
        productDisplayable: 'p_displayable|GT+0',
        productShoppable: 'p_shoppable|GT+0'
      },

      defaultRangeFilters: ['skuSearchable'],

      configuredRecordFilters: {
        products: 'rec_type:product',
        content: 'rec_type:content',
        locale: 'locale:' + (jQuery.cookie('LOCALE') || Drupal?.settings?.endeca?.locale),
        activeSkus: 'NOT(s_INVENTORY_STATUS:5)',
        discontinued: 'NOT(s_discontinued:1)',
        shoppableOrComingSoon: 'OR(s_shoppable:1,s_INVENTORY_STATUS:3)',
      }
    },

    mustacheMappings: (function() {
      var combine = function(keys, values) {
        var temp = [];
        for (i = 0, j = keys.length; i < j; i++) {
          temp[keys[i]] = values[i];
        }
        return temp;
      };

      var templates = _.keys(site.templates);
      return combine(templates, templates);
    })(),

    coremetricsEnabled: false,
    omnitureEnabled: true,

    contentzones: {},
    mixins: {}
  };
});
